
import {Vue, Component} from 'vue-property-decorator';
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TakipEkAlanTuruForm from "@/components/forms/TakipEkAlanTuruForm.vue";
import TakipAlacakliPicker from "@/components/pickers/TakipAlacakliPicker.vue";
import SabitAlacakliPicker from "@/components/pickers/SabitAlacakliPicker.vue";

@Component({
  components: {SabitAlacakliPicker, TakipAlacakliPicker, TakipEkAlanTuruForm, DeleteDialog, FormDialog}
})
export default class SabitAlacakliList extends Vue {

  items:any = []

  headers= [
    {text: "Adı", align: "start", sortable: false, value: "ad"},
    {text: "Vergi / TC Kimlik No", value: "no"},
  ]

}
